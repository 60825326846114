import { useStaticQuery, graphql } from 'gatsby'

export const useServices = () => {
  const services = useStaticQuery(
    graphql`
      query ServicesQuery {
        sections: allServicesYaml {
          edges {
            node {
              section
              services
            }
          }
        }
      }
    `
  )
  return services.sections.edges
}
