import React from 'react'
import { Button, HStack } from '@chakra-ui/react'
import { Link } from '@adamquadmon/gatsby-theme-lucifero'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ActionButtons = () => {
  const { t } = useTranslation()
  return (
    <HStack spacing={1}>
      <Button as={Link} to={t('linkBook')} variant="cta" size="sm">
        {t('book')}
      </Button>
    </HStack>
  )
}

export default ActionButtons
