// i18next-extract-mark-ns-start services
import React from 'react'
import {
  Box,
  Text,
  Flex,
  Stack,
  Heading,
  Icon,
  useStyleConfig,
} from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useServices } from '../hooks/use-services'
import { getIcon } from '../components/Icons'

const Service = ({ service }) => {
  const { t } = useTranslation()
  const ServiceIcon = getIcon(service)
  return (
    <Flex>
      <Icon w={[8, 10, 4]} h={[8, 10, 4]} color="gray.200">
        <ServiceIcon />
      </Icon>
      {/* i18next-extract-disable-next-line */}
      <Text>{t(service)}</Text>
    </Flex>
  )
}

const ServicesSection = ({ section, services }) => {
  const { t } = useTranslation()
  return (
    <Stack className="section" direction={['row', '', 'column']}>
      {/* i18next-extract-disable-next-line */}
      <Heading as="h3">{t(section)}</Heading>
      <Stack className="services">
        {services.map((service) => (
          <Service service={service} key={service} />
        ))}
      </Stack>
    </Stack>
  )
}

const Services = ({ title, titleAs, size, spacing, variant }) => {
  const styles = useStyleConfig('Services', { variant })
  const sections = useServices()

  return (
    <Box __css={styles}>
      <Box textAlign={'center'} align={'center'}>
        <Heading as={titleAs} size={size}>
          {title}
        </Heading>
      </Box>
      <Stack direction={['column', '', 'row']}>
        {sections.map(({ node: { section, services } }) => (
          <ServicesSection
            key={section}
            section={section}
            services={services}
          />
        ))}
      </Stack>
    </Box>
  )
}

Services.defaultProps = {
  spacing: 2,
  size: 'xl',
  titleAs: 'h2',
}

export default Services
