import React from 'react'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { Cards } from '@adamquadmon/gatsby-theme-lucifero/'
import { useListings } from '../hooks/use-listings'

const Listings = ({ exclude, ...rest }) => {
  const { language } = React.useContext(I18nextContext)
  const listings = useListings(language, exclude)

  return <Cards nodes={listings} {...rest} />
}

export default Listings
