import { ReactComponent as CheckIn } from '../icons/log-in-arrow.svg'
import { ReactComponent as CheckOut } from '../icons/log-out-arrow.svg'
import { ReactComponent as HairDryer } from '../icons/hair-dryer.svg'
import { ReactComponent as SecureCode } from '../icons/password.svg'
import { ReactComponent as Parking } from '../icons/parking.svg'
import { ReactComponent as Keys } from '../icons/two-keys.svg'
import { ReactComponent as Bed } from '../icons/bed.svg'
import { ReactComponent as Remote } from '../icons/remote-control.svg'
import { ReactComponent as Chimney } from '../icons/chimney.svg'
import { ReactComponent as Clima } from '../icons/air-conditioner.svg'
import { ReactComponent as Tv } from '../icons/tv.svg'
import { ReactComponent as Wifi } from '../icons/wifi.svg'
import { ReactComponent as Bathroom } from '../icons/sink-bathroom.svg'
import { ReactComponent as Towel } from '../icons/towel.svg'
import { ReactComponent as Breakfast } from '../icons/breakfast.svg'
import { ReactComponent as Fridge } from '../icons/fridge.svg'
import { ReactComponent as Grill } from '../icons/grill.svg'
import { ReactComponent as Kettle } from '../icons/kettle.svg'
import { ReactComponent as Coffee } from '../icons/coffee.svg'
import { ReactComponent as Tea } from '../icons/tea.svg'
import { ReactComponent as Microwave } from '../icons/microwave.svg'
import { ReactComponent as Oven } from '../icons/oven.svg'
import { ReactComponent as Cooking } from '../icons/cooking.svg'
import { ReactComponent as Park } from '../icons/childhood-park.svg'
import { ReactComponent as Pool } from '../icons/swimming-pool.svg'
import { ReactComponent as Horse } from '../icons/horse.svg'
import { ReactComponent as Hen } from '../icons/hen.svg'
import { ReactComponent as Cat } from '../icons/egyptian-cat.svg'
import { ReactComponent as Dog } from '../icons/cane.svg'
import { ReactComponent as Olive } from '../icons/olive.svg'
import { ReactComponent as Fruit } from '../icons/fruit-tree.svg'
import { ReactComponent as Vegetables } from '../icons/vegetables.svg'
import { ReactComponent as TuristicMap } from '../icons/touristic-map.svg'
import { ReactComponent as Wine } from '../icons/wine.svg'
import { ReactComponent as Taxi } from '../icons/taxi.svg'
import { ReactComponent as Exchange } from '../icons/exchange-change.svg'
import { ReactComponent as Cleaning } from '../icons/cleaning-lady.svg'
import { ReactComponent as Catering } from '../icons/catering-buffet.svg'
import { ReactComponent as Netflix } from '../icons/netflix.svg'
import { ReactComponent as Ironing } from '../icons/ironing.svg'
import { ReactComponent as Washing } from '../icons/washing-machine.svg'
import { ReactComponent as Luggage } from '../icons/luggage.svg'
import { ReactComponent as Crib } from '../icons/crib.svg'

import { FaShower } from 'react-icons/fa'

const icons = {
  checkin: CheckIn,
  checkout: CheckOut,
  selfcheckin: SecureCode,
  keys: Keys,
  remote: Remote,
  parking: Parking,
  bed: Bed,
  crib: Crib,
  chimney: Chimney,
  clima: Clima,
  tv: Tv,
  wifi: Wifi,
  wc: Bathroom,
  sheets: Towel,
  shower: FaShower,
  'hair-dryer': HairDryer,
  breakfast: Breakfast,
  fridge: Fridge,
  bbq: Grill,
  kettle: Kettle,
  coffee: Coffee,
  teas: Tea,
  microwave: Microwave,
  hoven: Oven,
  kitchen: Cooking,
  garden: Park,
  pool: Pool,
  horse: Horse,
  hen: Hen,
  cat: Cat,
  dog: Dog,
  olive: Olive,
  fruit: Fruit,
  veggie: Vegetables,
  turism: TuristicMap,
  wine: Wine,
  transfert: Taxi,
  change: Exchange,
  clean: Cleaning,
  catering: Catering,
  netflix: Netflix,
  iron: Ironing,
  washing: Washing,
  luggages: Luggage,
}

const getIcon = (iconName) => {
  return icons[iconName] ? icons[iconName] : icons['in']
}

export default icons
export { getIcon }
