import React from 'react'
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'
import { useListings } from '../../../hooks/use-listings'
// import { useNearby } from '../../../hooks/use-listings'

// TODO use normal links and don't translate slugs
// TODO usePlaces to render shops, restaurants and places
export const useNavItems = () => {
  const { language } = React.useContext(I18nextContext)
  const { t } = useTranslation()
  const listings = useListings(language)
  // const nearby = useNearby(language)
  return [
    {
      label: t('theLodges'),
      href: t('linkLodges'),
      children: listings,
    },
    {
      label: t('theRanch'),
      href: t('linkRanch'),
      // children: location,
    },
    {
      label: t('thePlaces'),
      href: t('linkPlaces'),
      // children: nearby,
    },
    {
      label: t('theGallery'),
      href: t('linkGallery'),
    },
  ]
}
