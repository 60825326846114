import { useStaticQuery, graphql } from 'gatsby'
import { edgesByLanguage } from '@adamquadmon/gatsby-theme-lucifero'

export const useListings = (language, exclude) => {
  const data = useStaticQuery(
    graphql`
      query ListingsQuery {
        allPage(
          filter: { type: { eq: "listing" }, published: { eq: true } }
          sort: { fields: [order], order: ASC }
        ) {
          ...PageEdges
        }
      }
    `
  )

  const { allPage } = data

  let listings = edgesByLanguage(allPage, language)

  if (exclude) {
    const excludeArray = exclude.split(',')
    listings = listings.filter(({ node }) => !excludeArray.includes(node.topic))
  }

  return listings
}
