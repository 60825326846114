import React from 'react'
import { Box, Heading, VStack, Text, useStyleConfig } from '@chakra-ui/react'
import { Image } from '@adamquadmon/gatsby-theme-lucifero'
import { Trans } from 'gatsby-plugin-react-i18next'

const Hero = ({ variant }) => {
  const styles = useStyleConfig('Hero', { variant })
  return (
    <VStack __css={styles}>
      <Box w={'100%'}>
        <Image
          file="LePietrebnb-esterno-chalet-pietra.jpg"
          folder="monolocale"
          imgixParams={{ crop: 'top' }}
        />
      </Box>
      <Heading as="h1" lineHeight="1.2">
        <Trans>heroTitle</Trans>
        <Text as={'span'}>
          <Trans>heroTitleHighlight</Trans>
        </Text>
      </Heading>
      <VStack className="message">
        <Heading as="h2">
          <Text as="span">
            <Trans>heroMessage1Span</Trans>
          </Text>
          <Trans>heroMessage1</Trans>
        </Heading>
        <Heading as="h3">
          <Text as="span">
            <Trans>heroMessage2Span</Trans>
          </Text>
          <Trans>heroMessage2</Trans>
        </Heading>
        <Heading as="h4">
          <Text as="span">
            <Trans>heroMessage3Span</Trans>
          </Text>
          <Trans>heroMessage3</Trans>
        </Heading>
      </VStack>
    </VStack>
  )
}

export default Hero
