import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Hero from '../../../components/Hero'
import Listings from '../../../components/Listings'
import Services from '../../../components/Services'

const Home = () => {
  const { t } = useTranslation()
  return (
    <>
      <Hero />
      <Listings title={t('theLodges')} variant="home" />
      <Services title={t('theServices')} />
    </>
  )
}

export default Home
